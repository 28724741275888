import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

// components
import Asset from '../components/asset/Asset';
import Layout from "../components/layout/Layout";
import Seo from "../components/seo/SEO";

// styles

const FilmsPage = () => {
  const query = useStaticQuery(graphql`
    {
      craft {
        entries(section: "films") {
          ... on Craft_films_films_Entry {
            id
            title
            typeHandle
            uri
            contentMatrix {
              ... on Craft_contentMatrix_contentBlock_BlockType {
                featuredImage {
                  ... on Craft_films_Asset {
                    title
                    url
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
                releaseYear
              }
            }
          }
        }
      }
    }
  `)

  const [size, setSize] = useState(3);

  const content = query.craft.entries;

  const contentEl = content.map((entry, i) => {
    // console.log('entry: ', entry);

    let image = null;
    image = entry.contentMatrix ? entry.contentMatrix[0].featuredImage[0] : null;

    return (
      <Link key={i} to={'/' + entry.uri} className={`content-table__item relative`}>
        {image ?
          <div className={`m-16`}>
            <Asset data={image.imageFile} title={image.title} />
          </div>
          : null
        }

        <div className={`content-table__vertical-text`}>
          <p className={`small`}><span className={`sans bold uppercase`}>{entry.contentMatrix[0].releaseYear}</span> </p>
        </div>

        <div className={`flex align-center justify-between`}>
          <h3 className={`m-0`}>{entry.title}</h3>
        </div>
      </Link>
    )
  });

  const sizeUp = () => {
    if (size > 2) {
      setSize(size - 1);
    }
  }

  const sizeDown = () => {
    if (size < 4) {
      setSize(size + 1);
    }
  }

  return (
    <Layout>
      <Seo title="Films" />
      <div className={`view-wrapper__controls grid grid--gap-4 grid--2`}>
        <button className={`view-wrapper__child button button--hover flex align-center justify-center`} onClick={() => sizeDown()}>
          <h3 className={`m-0`}>-</h3>
        </button>
        <button className={`view-wrapper__child button button--hover flex align-center justify-center`} onClick={() => sizeUp()}>
          <h3 className={`m-0`}>+</h3>
        </button>
      </div>
      
      <div className={`content-table grid grid--gap-4 grid--${size}`}>
        {contentEl}
      </div>
    </Layout>
  )
}

export default FilmsPage
